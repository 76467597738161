<template>
  <div :class="point.post_status ==='closed'?'overlay':''">

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <section
      :class="itemView"
      class="proposals"
    >
      <b-row class="match-height" v-if="proposals && proposals.length">
        <b-col
          v-for="proposal in proposals"
          :key="proposal.id"
          cols="12"
        >
          <b-card
              class="ecommerce-card"
              no-body
          >
            <div class="position-absolute statuses">
              <b-badge
                  v-if="point.receiver && point.receiver.id===proposal.organizer.id"
                  variant="light-warning"
              >
                <small class="text-nowrap">
                  {{ $t('event.direct_request_proposal') }}
                  <feather-icon
                      icon="StarIcon"
                  />
                </small>
              </b-badge>

            </div>
            <b-card-body class="mt-1">
              <b-row v-if="ability.can('update', point)">
                <b-col cols="12" sm="6" >
                  <b-link @click="handleProviderViewActionClick(proposal.organizer)">
                    <div class="text-center">
                      <profile-avatar :user="proposal.organizer" avatarSize="72" />
                      <h5>{{ proposal.organizer.name }}</h5>
                      <!--                      <b-card-text>{{ proposal.organizer.bio }}</b-card-text>-->
                      <!--                  <h6 class="text-muted">-->
                      <!--                    Malaysia-->
                      <!--                  </h6>-->
                      <!--                  <b-badge-->
                      <!--                    class="profile-badge"-->
                      <!--                    variant="light-primary"-->
                      <!--                  >-->
                      <!--                    Pro Level-->
                      <!--                  </b-badge>-->
                      <profile-languages :user="proposal.organizer" />
                    </div>
                  </b-link>

                </b-col>
                  <b-col class="match-height d-flex justify-content-center align-items-center">
                   <proposal-details :proposal="proposal"></proposal-details>
                  </b-col>
              </b-row>
              <b-row v-else>
                <b-col class="match-height">
                 <proposal-details :proposal="proposal"></proposal-details>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer>
              <b-row>
                <b-col cols="12" md="6">
                  <div>{{ $t('Created') }}: {{ proposal.extendedProps.submit_date | formatDateTime() }}</div>
                  <div class="text-capitalize" v-if="proposal.extendedProps.updated_date!==proposal.extendedProps.submit_date">
                    {{ $t('updated') }}: {{ proposal.extendedProps.updated_date | formatDateTime() }}
                  </div>
                  <div
                      v-if="proposal.extendedProps.accept_date"
                      class="text-success"
                  >{{ $t('Accepted') }}: {{ proposal.extendedProps.accept_date | formatDateTime() }}</div>
                  <div
                      v-if="proposal.extendedProps.decline_date"
                      class="text-danger"
                  >{{ $t('Declined') }}: {{ proposal.extendedProps.decline_date | formatDateTime() }}<br>
                    {{ $t('Reason') }}: {{ getDeclineReasonTitle(proposal.extendedProps.decline_reason) }}
                  </div>
                  <div
                      v-if="proposal.extendedProps.withdraw_date"
                      class="text-warning"
                  >{{ $t('Withdrawn') }}: {{ proposal.extendedProps.withdraw_date | formatDateTime() }}<br>
                    {{ $t('Reason') }}: {{ getWithdrawReasonTitle(proposal.extendedProps.withdraw_reason) }}
                  </div>
                </b-col>
              </b-row>
            </b-card-footer>
            <!-- Product Actions -->
            <div class="item-options text-center">
              <b-button
                  v-if="eventCanBeAccepted(proposal)"
                  variant="success"
                  class="btn-wishlist"
                  @click="handleAcceptProposalActionClick(proposal)"
              >
                <div
                    v-if="submittingFetch"
                >
                  <span class="align-middle">
                      <b-spinner
                          type="grow"
                          small
                          class="mr-50"
                      />
                    {{ $t('Loading...') }}
                  </span>
                </div>
                <div v-else>
                  <feather-icon
                      icon="ThumbsUpIcon"
                      class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Accept') }}</span>
                </div>

              </b-button>
              <b-button
                  v-if="eventCanBeDeclined(proposal)"
                  variant="danger"
                  class="btn-wishlist"
                  @click="handleDeclineProposalActionClick(proposal)"
              >
                <div
                    v-if="submittingFetch"
                >
                  <span class="align-middle">
                     <b-spinner
                         type="grow"
                         small
                         class="mr-50"
                     />{{ $t('Loading...') }}</span>
                </div>
                <div v-else>

                  <span class="align-middle">                  <feather-icon
                      icon="ThumbsDownIcon"
                      class="mr-50"
                  />{{ $t('Decline') }}</span>
                </div>

              </b-button>
              <b-button
                  v-if="eventCanBeDeleted(proposal)"
                  variant="danger"
                  class="btn-wishlist"
                  @click="handleDeleteProposalActionClick(proposal)"
              >
                <div
                    v-if="submittingFetch"
                >
                  <span class="align-middle">
                      <b-spinner
                          type="grow"
                          small
                          class="mr-50"
                      />
                    {{ $t('Loading...') }}</span>
                </div>
                <div v-else>
                  <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                  />
                  <span class="align-middle">{{ $t('event.delete') }}</span>
                </div>

              </b-button>
              <b-button
                  v-if="eventCanBeWithdrawn(proposal)"
                  :disabled="proposal.extendedProps.withdraw_date !== null"
                  :variant="proposal.extendedProps.withdraw_date!== null?'dark':'warning'"
                  class="btn-wishlist"
                  @click="handleWithdrawProposalActionClick(proposal)"
              >
                <div
                    v-if="submittingFetch"
                >
                  <span class="align-middle">
                    <b-spinner
                        type="grow"
                        small
                        class="mr-50"
                    />
                    {{ $t('Loading...') }}</span>
                </div>
                <div v-else>
                  <feather-icon
                      icon="RotateCwIcon"
                      class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Withdraw') }}</span>
                </div>

              </b-button>
              <b-button
                  v-if="eventCanBeUpdated(proposal)"
                  variant="primary"
                  class="btn-wishlist"
                  @click="handleEditProposalActionClick(proposal)"
              >
                <div
                    v-if="submittingFetch"
                >
                  <span class="align-middle">
                    <b-spinner
                        type="grow"
                        small
                        class="mr-50"
                    />
                    {{ $t('Loading...') }}</span>
                </div>
                <div v-else>
                  <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Edit') }}</span>
                </div>

              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col
            cols="12"
        >
          <b-card
              class="ecommerce-card"
              no-body
          >
            <b-card-body>
              <b-card-text v-if="totalProposals===0">
                <span>{{$tc('proposals_found',0)}}</span>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <!-- Pagination -->
    <section v-if="totalProposals">
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProposals"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BButton,
  BPagination,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BTab,
  BTabs,
  BBadge,
  BAlert,
  BCardFooter,
  BSpinner,
  BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import Proposal from '@/mixins/classes/proposal'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import store from '@/store'
import { getPostStatusProperty, getUserAvatarText } from '@/mixins/functions'
import { currency, event } from '@/mixins/options'
import { showToast } from '@/mixins/notification/toasts'
import { formatDateTime } from '@/filters/dateTime'
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter'
import ProfileAvatar from '@/views/pages/profile/ProfileAvatar.vue'
import ProfileLanguages from '@/views/pages/profile/ProfileLanguages.vue'
import { useShopFiltersSortingAndPagination, useShopUi } from './useProposalList'
import LeftFilterSidebar from './ProposalListLeftFilterSidebar.vue'
import { useEventsUI, useProposalRemoteData, manageProposalActions } from '../useProposals'
import ProposalDetails from '@/views/apps/service/proposal/details/ProposalDetails.vue'
import { useUserUi } from '@/views/apps/user/useUser'
export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV

    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BButton,
    BPagination,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BTab,
    BTabs,
    BBadge,
    BAlert,
    BCardFooter,
    BSpinner,
    BLink,

    ProfileAvatar,
    ProfileLanguages,
    ProposalDetails,
    // SFC
    LeftFilterSidebar,

  },
  filters: {
    formatDateTime,
  },
  mixins: [getPostStatusProperty, getUserAvatarText, showToast, event],
  props: {
    point: {
      type: Object,
      default: () => {},
    },
  },

  created() {
    GlobalEventEmitter.$on('request-closed', () => {
      this.fetchFilteredProposals()
    })
  },
  methods: {
    handleAcceptProposalActionClick(proposal) {
      this.submittingFetch = true
      if (this.acceptedProposal) {
        this.showToast({ status: 'error', statusText: 'Another proposal accepted' }, 'Error')
        return false
      }
      this.$swal({
        title: this.$t('Are you sure?'),
        icon: 'question',
        html: `${this.$t('After accepting this proposal other ones will be automatically declined')}! &#128070;`,
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        footer: !this.eventDateIsActual(proposal) ? `<span class=text-center>${this.$t('message.warning')}! ${this.$t('event.date_and_time_need_to_be_clarified')} &#x1F550</span>` : '',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },

        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.submittingFetch = true
            this.acceptProposal(proposal.id).then(response => {
              const successStatuses = [200, 201]
              if (successStatuses.includes(response.status)) {
                this.showToast(response, null, null, 'Proposal Accepted')
                this.fetchFilteredProposals()
              }
            })
          }
        }).finally(()=>{this.submittingFetch = false})

      this.submittingFetch = false
    },
    handleDeclineProposalActionClick(proposal) {
      this.submittingFetch = true
      if (proposal.extendedProps.accept_date !== null) {
        const errorMessage = this.$t('Please, select a decline reason')
        this.$swal({
          title: this.$t('Are you sure?'),
          icon: 'question',
          html: `${this.$t('Declining an accepted proposal may negatively affect your rating')}! &#128533;`,
          input: 'select',
          inputOptions: this.getDeclineReasons(),
          inputPlaceholder: this.$t('Decline Reason'),
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1',
          },

          buttonsStyling: false,
          inputValidator(value) {
            return new Promise(((resolve, reject) => {
              if (value !== '') {
                resolve()
              } else {
                resolve(errorMessage)
              }
            }))
          },
        })
          .then(result => {
            this.submittingFetch = true
            if (result.isConfirmed) {
              const payload = {
                id: proposal.id,
                decline_reason: result.value,
              }
              this.declineProposal(payload).then(response => {
                const successStatuses = [200, 201]
                if (successStatuses.includes(response.status)) {
                  this.showToast(response, null, null, 'Proposal Declined')
                  if(['request_non_actual'].includes(result.value)){
                    GlobalEventEmitter.$emit('close-request', {close_reason:result.value})
                  } else {
                    this.fetchFilteredProposals()
                  }
                } else {
                  this.showToast(response, 'Proposal')
                }
              }).finally(()=>{this.submittingFetch=false})
            }
          })
        this.submittingFetch = false
      }
    },
    handleWithdrawProposalActionClick(proposal) {
      this.submittingFetch = true
      const errorMessage = this.$t('Please, select a withdraw reason')
      this.$swal({
        title: this.$t('Are you sure?'),
        icon: 'question',
        html: `${this.$t('Withdrawing an accepted proposal may negatively affect your rating')}! &#128533;`,
        input: 'select',
        inputOptions: this.getWithdrawReasons(),
        inputPlaceholder: this.$t('Withdraw Reason'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },

        buttonsStyling: false,
        inputValidator(value) {
          return new Promise(((resolve, reject) => {
            if (value !== '') {
              resolve()
            } else {
              resolve(errorMessage)
            }
          }))
        },
      })
        .then(result => {
          this.submittingFetch = true
          if (result.isConfirmed) {
            this.submittingFetch = true
            const payload = {
              id: proposal.id,
              withdraw_reason: result.value,
            }
            this.withdrawProposal(payload).then(response => {
              const successStatuses = [200, 201]
              if (successStatuses.includes(response.status)) {
                this.showToast(response, null, null, 'Proposal Withdrawn')
                this.fetchFilteredProposals()
              } else {
                this.showToast(response, 'Proposal')
              }
            })
          }
        }).finally(()=>{this.submittingFetch = false})

      this.submittingFetch = false
    },
    handleDeleteProposalActionClick(proposal) {
      this.submittingFetch = true
      this.$swal({
        title: this.$t('Are you sure?'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },

        buttonsStyling: false,
      })
        .then(result => {
          this.submittingFetch = true
          if (result.isConfirmed) {
            this.submittingFetch = true
            const payload = {
              id: proposal.id,
            }
            this.deleteProposal(payload).then(response => {
              const successStatuses = [200, 201]
              if (successStatuses.includes(response.status)) {
                this.showToast(response, null, null, 'event.proposal_removed')
                this.fetchFilteredProposals()
              } else {
                this.showToast(response, 'Proposal')
              }
            }).finally(()=>{this.submittingFetch = false})
          }
        })

      this.submittingFetch = false
    },

  },
  setup(props, { emit }) {
    const submittingFetch = ref(false)
    const {
      getDeclineReasonTitle, getWithdrawReasonTitle, getWithdrawReasons, getDeclineReasons,
    } = event
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const {
      handleCartActionClick, toggleProductInWishlist, handleEditRequestActionClick, eventCanBeUpdated,
      eventCanBeWithdrawn, eventCanBeDeclined, eventCanBeAccepted, eventCanBeDeleted, eventAccepted, eventIsActual, eventDateIsActual,
    } = useEventsUI()

    const { handleAddProposalActionClick, handleEditProposalActionClick } = manageProposalActions()

    const {
      itemView, itemViewOptions, totalProposals,
    } = useShopUi()

    const ability = defineAbilityForCurrentUser()

    const {
      proposals, fetchProposals, acceptProposal,
      declineProposal, withdrawProposal, deleteProposal,
    } = useProposalRemoteData()

    const {
      acceptedProposal, getAcceptedProposal, proposalCanBeAdded,proposalAdded,
    } = manageProposalActions()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()


    // Wrapper Function for `fetchProposals` which can be triggered initially and upon changes of filters
    const fetchFilteredProposals = () => {
      submittingFetch.value = true

      const args = {
        page: filters.value.page,
        per_page: filters.value.perPage,
      }
      // if (filters.value.proposalPurposes.length !== 0) {
      //   args.purpose_ids = filters.value.proposalPurposes.join()
      // }

      if (filters.value.q) {
        args.q = filters.value.q
      }

      if (sortBy.value.value) {
        args.sort_by = sortBy.value.value
      }

      if (filters.value.userId) {
        args.user_id = filters.value.userId
      }

      if (filters.value.postStatuses.length !== 0) {
        args.post_statuses = filters.value.postStatuses.join()
      }
      args.subject_id = props.point.id
      args.include = ['organizer.languages', 'organizer.service_offers'].join(',')
      args.order = 'desc'
      fetchProposals(args)
        .then(response => {
          const { total } = response.data.meta
          const successStatuses = [200, 201]
          if (successStatuses.includes(response.status)) {
            proposals.value = store.getters['proposal/proposals'].map($item => new Proposal($item))
            acceptedProposal.value = getAcceptedProposal(proposals.value)
            // proposals.value.sort(a => eventIsActual(a) ? -1 : 1);
          }
          totalProposals.value = total
          emit('total-proposals', total)
          emit('proposal-can-be-added', proposalCanBeAdded(proposals.value, props.point))
          emit('proposal-added', proposalAdded(proposals.value, props.point))
        }).finally(()=>{submittingFetch.value=false})
    }

    watch([filters, sortBy], () => {
      fetchFilteredProposals()
    }, {
      deep: true,
      immediate: true,
    })
    const { getCurrencySymbol } = currency()

    const { handleProviderViewActionClick } = useUserUi()
    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProposals,
      toggleProductInWishlist,
      handleCartActionClick,
      handleEditRequestActionClick,
      handleAddProposalActionClick,
      handleEditProposalActionClick,
      proposalCanBeAdded,
      eventAccepted,
      eventCanBeUpdated,
      eventCanBeWithdrawn,
      eventCanBeDeclined,
      eventCanBeAccepted,
      eventCanBeDeleted,
      eventIsActual,
      eventDateIsActual,


      // useProposalRemoteData
      proposals,
      fetchFilteredProposals,

      ability,

      // manageProposalActions
      acceptedProposal,
      acceptProposal,
      declineProposal,
      withdrawProposal,
      deleteProposal,
      getDeclineReasonTitle,
      getWithdrawReasonTitle,
      getWithdrawReasons,
      getDeclineReasons,
      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,

      getCurrencySymbol,
      submittingFetch,
      handleProviderViewActionClick
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.media{
  margin-bottom: 1rem;
}
.statuses{
  top: -1px!important;
  right:0;
}
.statuses>span{
  border-radius: 0;
  margin-right: 0!important;
}
.statuses>span:first-child:last-child {
  border-radius:  0 0.428rem 0 0.428rem;
}
.statuses>span:first-child {
  border-radius:  0 0 0 0.428rem;
}
.statuses>span:last-child {
  border-radius:  0 0.428rem 0 0 ;
}
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.search-proposal.form-control{
  height: auto;
}

[dir] .ecommerce-application .grid-view .ecommerce-card .item-img {
  padding-top: 0;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  min-height: 15.85rem;
  display: block;
  align-items: center;
}
.ecommerce-application .grid-view .ecommerce-card .item-options {
  flex-wrap: nowrap;
}
.card-img-top {
  max-height: 20em;
  object-fit: cover;
}
.dark-layout .ecommerce-application .ecommerce-card .btn-light {
  background-color: #242B3D !important;
}
.proposals.grid-view{
  margin-top: 0!important;
  grid-template-columns: none;
}

</style>
