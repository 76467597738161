<template>
  <b-row class="py-1">
    <!-- Appointment Date  -->
    <b-col
      cols="12"
      sm="6"
      class="order-0"
      :class="proposalDateNeedsToBeClarified(proposal)?'text-danger':''"
    >
      <div
        class="d-flex justify-content-start align-items-center mb-75"
      >
        <b-avatar
          rounded
          size="32"
          class="mr-75"
          variant="light-primary"
        >
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
        </b-avatar>
        <div class="font-weight-bold font-medium-4">
          {{ proposal.extendedProps.appointment || proposal.extendedProps.start | formatDateTime('DD.MM.YYYY') }}
        </div>
      </div>
    </b-col>
    <!-- Price -->
    <b-col
      cols="12"
      sm="6"
      class="order-2 order-sm-1"
    >
      <div
        class="d-flex justify-content-start align-items-center mb-75"
      >
        <b-avatar
          rounded
          size="32"
          class="mr-75"
          variant="light-primary"
        >
          <feather-icon
            icon="CreditCardIcon"
            size="16"
          />
        </b-avatar>
        <div class="font-weight-bold font-medium-4">
          <small>{{ $t('from') }} </small>{{ getCurrencySymbol(proposal.extendedProps.currency) }}{{ proposal.extendedProps.min_price }}
        </div>
      </div>
    </b-col>
    <!-- Appointment Time  -->
    <b-col
      cols="12"
      sm="6"
      class="order-1 order-sm-2"
      :class="proposalDateNeedsToBeClarified(proposal)?'text-danger':''"
    >
      <div
        class="d-flex justify-content-start align-items-center mb-75"
      >
        <b-avatar
          rounded
          size="32"
          class="mr-75"
          variant="light-primary"
        >
          <feather-icon
            icon="ClockIcon"
            size="16"
          />
        </b-avatar>
        <div>
          <div class="font-weight-bold font-medium-4">
            {{ proposal.start | formatDateTime('HH:mm') }}
          </div>
          <div><small>({{ proposal.start | formatDateTime('HH:mm') }} - {{ proposal.end | formatDateTime('HH:mm') }})</small></div>
        </div>

      </div>
    </b-col>
    <!-- Description -->
    <b-col
      v-if="proposal.extendedProps.description"
      cols="12"
      sm="6"
      class="order-4"
    >
      <div
        class="d-flex justify-content-start align-items-center mb-75"
      >
        <b-avatar
          rounded
          size="32"
          class="mr-75"
          variant="light-primary"
        >
          <feather-icon
            icon="MessageCircleIcon"
            size="16"
          />
        </b-avatar>
        <div class="font-weight-bold overflow-auto">
          {{ proposal.extendedProps.description }}
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>

import {
  BRow, BCol, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useEventsUI } from '@/views/apps/service/proposal/useProposals'
import { currency } from '@/mixins/options'
import { formatDateTime } from '@/filters/dateTime'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BAvatar,
  },
  filters: {
    formatDateTime,
  },
  props: {
    proposal: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {

    }
  },
  setup() {
    const {
      handleCartActionClick, toggleProductInWishlist, handleEditRequestActionClick, userPhones, eventCanBeUpdated,
      eventCanBeWithdrawn, eventCanBeDeclined, eventCanBeAccepted, eventCanBeDeleted, eventAccepted, eventIsActual, eventDateIsActual, proposalDateNeedsToBeClarified,
    } = useEventsUI()

    const { getCurrencySymbol } = currency()
    return {
      proposalDateNeedsToBeClarified,
      getCurrencySymbol,
    }
  },
}
</script>

<style lang="scss"></style>
