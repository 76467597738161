<template>

  <div
    v-if="request.address && request.address.country !== null"
    class="d-flex justify-content-start align-items-center mb-75"
  >
    <b-avatar
      rounded
      size="32"
      class="mr-75"
      variant="light-primary"
    >
      <feather-icon
        icon="MapPinIcon"
        size="16"
      />
    </b-avatar>
    <div>
      <request-open-map
        :data="request"
      />
      <b-alert
        v-if="request.address_limited"
        show
        variant="dark"
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="InfoIcon"
          />
          <span class="ml-25">{{ $t('Full address is displayed only for the service provider who made the service proposal') }}</span>
        </div>
      </b-alert>
    </div>
  </div>

</template>

<script>
import {
  BAvatar, BAlert,
} from 'bootstrap-vue'

import {
  getFormattedAddress,
} from '@/mixins/functions'

import RequestOpenMap from './RequestOpenMap.vue'

export default {
  components: {
    RequestOpenMap,
    BAvatar,
    BAlert,

  },
  mixins: [getFormattedAddress],
  props: {
    request: {
      type: Object,
      default: () => {},
      required: false,
    },
  },

}
</script>
